html {
  scroll-behavior: smooth;
}

body {
  -webkit-font-smoothing: antialiased;
  background-color: var(--colors-white);
  --colors-eiffel-blue: #0064ac;
  --colors-eiffel-blue-variant-dark: #014a7e;
  --colors-eiffel-blue-variant-light: #bfd8ea;
  --colors-primary-blue: #1f48e7;
  --colors-primary-royal-blue: #f2f4fe;
  --colors-secondary-dark-blue: #052d69;
  --colors-secondary-light-blue: #22b3f2;
  --colors-secondary-navy-blue: #0d2481;
  --colors-secondary-purple: #8058ff;
  --colors-secondary-light-purple: #e5deff;
  --colors-secondary-dark-purple: #4a27b9;
  --colors-secondary-black: #120a23;
  --colors-secondary-cyan: #00cccc;
  --colors-secondary-icon-gray: #b7b5bd;
  --colors-secondary-waveform-gray: #cccccc;
  --colors-secondary-user-icon-background: #b0cfd7;
  --colors-sumo-sidebar-gray: #f6f5f6;
  --colors-white: #ffffff;
  --colors-background-gray: #f6f5f6;
  --colors-very-light-gray: #c8c8c8;
  --colors-light-gray: #c4c4c4;
  --colors-dark-gray: #545454;
  --colors-very-dark-gray: #434147;
  --colors-dark-gray-card: #222222;
  --colors-black: #000000;
  --colors-secondary-blue-on-black-shadow: #888491;
  --colors-secondary-blue-on-black-light: #362f44;
  --colors-navy-purple: #2e273d;
  --colors-functional-red: #cc0000;
  --colors-alert-red: #d90000;
  --colors-alert-red-light: #ffe5e5;
  --colors-functional-blue: #1890ff;
  --colors-functional-orange: #fa9614;
  --colors-functional-green: #16b757;
  --colors-bright-orange: #ff4e00;
  --colors-bright-green: #39ff14;
  --colors-pale-red: #fae6e6;
  --colors-light-grey: #e5e5e5;
  --colors-background-big-sky: #ebf3f8;
  --colors-background-grey-room: #d9d8dc;
  --colors-secondary-blue-on-black: #120a23;
  --colors-background-grey-room: #d9d8dc;
  --colors-enterprise-bruised: #595465;
  --colors-info-blue: #006cd1;
  --colors-warning-yellow: #fc9511;
  --colors-error-red: #d90000;
  --colors-success-green: #13b574;
  --colors-success-green-dark: #09784c;
  --colors-success-green-light: #e7f6e6;
  --colors-info-blue: #024a8c;
  --colors-info-blue-light: #e3f3ff;
  --colors-background-fade-to-grey: #f0f0f0;
  --colors-background-basalt: #141414;
  --colors-text-grey: #ADADAD;
}

* {
  box-sizing: border-box;
}

h1.ant-typography,
h2.ant-typography,
h3.ant-typography,
h4.ant-typography,
h5.ant-typography {
  font-weight: 900;
}

.file-download-popover 
  .ant-popover-arrow {
    display: none;
  }

.file-download-popover .ant-popover-inner {
  background-color: #222222;
}
