.nav-menu-overlay-desktop {
  z-index: 9999;
  position: fixed;
  top: 66px !important;
  padding-top: 0 !important;
  color: black !important;
  background-color: #fff !important;
  border-radius: 10px !important;
}

.nav-menu-overlay.mobile {
  top: 54px !important;
}

.nav-menu-overlay.desktop {
  top: 66px !important;
  position: fixed;
}

.nav-menu-overlay .ant-dropdown-arrow {
  top: -16px;
  background-color: white;
}
